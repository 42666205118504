<template>
  <div class="web_box">
    <van-nav-bar
      title=""
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class details"
      :border="false"
      indicator-color="#232A44"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in swiperList" :key="index">
        <img :src="item" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="pro_title">
      <p>{{ dataInfo.title }}</p>
    </div>
    <div class="pic_text_content" v-if="this.$route.query.type == 1">
      <div class="text" v-html="dataInfo.reamrk">
        {{ dataInfo.reamrk }}
      </div>
    </div>
    <div class="other_container" v-if="this.$route.query.type == 1">
      <h3>健康指导：</h3>
      <van-divider class="divider_line" />
      <div class="text" v-html="dataInfo.healthGuide">
        {{ dataInfo.healthGuide }}
      </div>
    </div>
    <div class="other_container" v-else>
      <h3>配方详情：</h3>
      <van-divider class="divider_line" />
      <div class="text" v-html="dataInfo.introduction">
        {{ dataInfo.introduction }}
      </div>
    </div>

    <div class="other_container mt108" v-if="this.$route.query.type == 1">
      <h3>注意事项：</h3>
      <van-divider class="divider_line" />
      <div class="text" v-html="dataInfo.attention">
        {{ dataInfo.attention }}
      </div>
    </div>
    <div class="button" @click="to_confirm_order">
      <p>立即预定</p>
    </div>
    <van-action-sheet
      v-model="boxShow"
      :round="false"
      :lock-scroll="true"
      :close-on-click-overlay="false"
    >
      <div class="hidden_box">
        <div class="close_hidden_box" @click="boxShow = false">
          <van-icon name="cross" />
        </div>

        <div class="production_box">
          <div class="product_pic">
            <img :src="orderGoods.picHead" alt="图片描述" />
          </div>
          <div class="product_texe">
            <p class="title">{{ orderGoods.title }}</p>
            <span>产品编号：{{ orderGoods.orderNo }}</span>
          </div>
        </div>
        <van-divider class="divider_line" />
        <div class="price">
          <p>&yen;{{ orderGoods.price }}</p>
        </div>
        <ul>
          <li @click="changePay(2)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>余额支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 2 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(1)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>微信支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 1 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(0)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>支付宝支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 0 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
        </ul>
        <div class="save_btn" @click="payOrder()">
          <button>确定支付</button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {Toast} from "vant";
import {
  getDeviceInfo,
  getPrescriptionInfo,
  CreatePrescription,
  orderPayment,
  findOrder,
} from "@/utils/api"; // 导入我们的api接口
export default {
  data() {
    return {
      boxShow: false,
      changeIndex: 2,
      dataInfo: "",
      swiperList: [],
      orderGoods: "",
    };
  },
  watch: {
    dataInfo: function() {
      this.$nextTick(function() {
        let imgList = document.getElementsByTagName("img");
        for (let i = 0; i < imgList.length; i++) {
          // console.log(imgList[i].src);
          if (imgList[i].src.indexOf("https")) {
            imgList[i].src = imgList[i].src.replace(new RegExp(/(http:)/), "");
          }
        }
      });
    },
  },
  mounted() {
    this.getInfo();
    // console.log(this.$route.query.id);
  },

  methods: {
    changePay(i) {
      this.changeIndex = i;
    },
    payOrder() {
      Toast.loading({
        message: "正在发起支付，请稍等...",
        forbidClick: true,
        duration: 0,
      });
      let obj = {
        orderNo: this.orderGoods.orderNo,
        price: this.orderGoods.price,
        orderType: 0,
      };
      window.localStorage.setItem("productionitem", JSON.stringify(obj));
      if (this.changeIndex == 2) {
        orderPayment({
          payType: this.changeIndex,
          orderNo: this.orderGoods.orderNo,
          channel: "APP",
          orderType: 0,
        })
          .then((res) => {
            setTimeout(() => {
              findOrder({orderNo: this.orderGoods.orderNo})
                .then((success) => {
                  Toast.clear();
                  if (success.map.status == "S") {
                    this.$router.push({
                      path: "/pay",
                      query: {
                        type: "success",
                      },
                    });
                    this.boxShow = false;
                  } else {
                    this.$router.push({
                      path: "/pay",
                      query: {
                        type: "error",
                        orderNo: this.orderGoods.orderNo,
                      },
                    });
                    this.boxShow = false;
                  }
                })
                .catch(() => {});
              console.log(res);
            }, 1000);
          })
          .catch(() => {});
      } else {
        Toast.clear();
        window.android.pay(this.orderGoods.orderNo, this.changeIndex, 0);
      }
    },
    to_confirm_order() {
      if (this.$route.query.type == 1) {
        this.$router.push({
          path: "/confirmOrder",
          query: {
            buyNum: 1,
            goodsId: this.$route.query.id,
          },
        });
      } else if (this.$route.query.type == 2) {
        CreatePrescription({
          orderForms: "Android",
          orderType: 1,
          prescriptionId: this.dataInfo.id,
        })
          .then((res) => {
            this.boxShow = true;
            this.orderGoods = res.data.orderGoods;
          })
          .catch(() => {});
      }
    },
    getInfo() {
      if (this.$route.query.type == 1) {
        getDeviceInfo({
          deviceId: this.$route.query.id,
        })
          .then((res) => {
            this.dataInfo = res.data.deviceInfo;
            this.swiperList = this.dataInfo.picUrls.split(";");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.$route.query.type == 2) {
        getPrescriptionInfo({
          prescriptionId: this.$route.query.id,
        })
          .then((res) => {
            this.dataInfo = res.data.prescriptionInfo;
            this.swiperList = this.dataInfo.picUrls.split(";");
            console.log(res.data.prescriptionInfo);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style lang="scss">
.van-swipe__indicator {
  background-color: #232a44 !important;
}
.van-swipe__indicator--active {
  background-color: #03bcec !important;
}
</style>
<style scoped>
.web_box >>> .text > div {
  color: #8cbcd6 !important;
  font-size: 26px !important;
}
.web_box >>> .text > p img {
  margin: 10px 0;
}
</style>
<style lang="scss" scoped>
.header_class.details {
  background-image: none;
  background-color: transparent;
}
.my-swipe {
  width: 100%;
  height: 750px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #232a44;
}
.pro_title {
  width: 100%;
  background-color: #12172c;
  p {
    padding: 32px;
    line-height: 37px;
    color: #00ccff;
  }
}
.pic_text_content {
  width: 100%;
  color: #8cbcd6;
  background: #12172c;
  margin-top: 20px;
  font-size: 26px;
  p {
    line-height: 40px;
  }
}
.text {
  padding: 32px;
  color: #8cbcd6 !important;
  font-size: 26px !important;
}

.divider_line {
  margin: 0 30px !important;
  background-color: #374372;
  //   padding: 0 auto;
  color: #374372;
  border-color: #374372;
}
.other_container {
  width: 100%;
  //   padding: 32px;
  background-color: #12172c;
  margin-top: 20px;
  h3 {
    padding: 30px 32px;
    color: #00ccff;
    font-size: 26px;
  }
}
.mt108 {
  margin-bottom: 108px;
}
.button {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #00ccff;
  color: #fff;
  text-align: center;
  p {
    line-height: 88px;
    font-size: 28px;
  }
}
.hidden_box {
  background-color: #232a44;
  position: relative;
  .close_hidden_box {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    color: #acafb8;
  }
  .production_box {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: flex-end;
    padding: 80px 0 40px;
    .product_pic {
      width: 120px;
      height: 120px;
    }
    .product_texe {
      width: 80%;
      margin-left: 30px;
      .title {
        color: #00ccff;
        font-size: 28px;
        margin-bottom: 10px;
      }
      span {
        color: #37708f;
        font-size: 24px;
      }
    }
  }
  .price {
    width: 90%;
    margin: 0 auto;
    p {
      color: #00ccff;
      font-size: 56px;
      padding: 50px 0;
      text-align: center;
    }
  }
  ul {
    width: 90%;
    margin: 0 auto;
    li {
      width: 100%;
      height: 119px;
      line-height: 119px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .letf_text {
        .icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          margin-right: 20px;
          background-size: 100% 100%;
          vertical-align: sub;
        }

        p {
          display: inline-block;
          color: #00ccff;
          font-size: 28px;
        }
      }

      .radio_icon {
        .icon {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .checked_true {
          background-image: url("../../assets/address/checked_true.png");
        }
        .checked_false {
          background-image: url("../../assets/address/checked_false.png");
        }
      }
    }
    li:first-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon1.png");
    }
    li:nth-of-type(2) > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon2.png");
    }
    li:last-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon3.png");
    }
  }
  .save_btn {
    widows: 100%;
    text-align: center;
    button {
      width: 90%;
      background: #00ccff;
      border-radius: 44px;
      height: 88px;
      text-align: center;
      border: none;
      outline: none;
      color: #fff;
      font-size: 28px;
      margin: 50px auto 40px;
    }
  }
}
</style>
